<template>
  <div class="warp">
    <div class="addr">
      <van-icon name="location-o" size="18"/>
      {{ address }}
    </div>
    <div class="box">
      <van-checkbox-group v-model="checked">
        <van-cell-group inset>
          <div class="idx-warp">
            <div class="idx-box" v-for="(item, index) in channelList" :key="item.idx" :class="item.curCt == 0 ? 'div-disabled' : ''" @click.stop="checkboxRefs[index] = item.idx">
              <div class="up-info">
                <div class="idx-nums">{{ item.idx }}</div>
                <div class="checkbox-item">
                  <van-checkbox :name="item.idx" :ref="el => checkboxRefs[index] = el" />
                </div>
              </div>
              <div class="middle-info">
                <div class="prod-name">{{ item.prodName }}</div>
                <van-image :src="item.pic" class="img-url"></van-image>
                <div class="empty" v-if="item.curCt == 0"></div>
                <div class="kong" v-if="item.curCt == 0">空</div>
              </div>
            </div>
          </div>
        </van-cell-group>
      </van-checkbox-group>
    </div>
    <div class="empty-div"></div>
    <div class="empty-div"></div>
  </div>
  <div class="btn-box">
    <div class="btn" @click="checkAll">
      <van-checkbox  v-model="checkedAll"/>全选
    </div>
    <div class="btn-2">
      <van-button type="primary" size="small" class="btn-two" @click="openLocks">开锁</van-button>
      <van-button type="primary" size="small" class="btn-two" @click="doReplace">换货</van-button>
      <van-button type="primary" size="small" class="btn-two" @click="doClear">清空</van-button>
      <van-button type="primary" size="small" class="btn-four" :loading="loading" @click="finishAddGoods">补货完成</van-button>
    </div>
  </div>
  <van-popup v-model:show="showGodds" position="bottom" :style="{ height: '75%' }" >
    <div class="popup-box">
      <div class="label">更换商品</div>
      <div v-for="item in goodsData" :key="item.prodId" :class="changedProdId == item.prodId ? 'changed card' : 'card'" @click.stop="changedProdId = item.prodId; changedProdName = item.prodName">
        <van-card
          :title="item.prodName || '不放置任何产品'"
          :thumb="item.pic"
          :price="item.price"
        />
      </div>
      <div class="empty-div"></div>
      <div class="btns">
        <van-button type="default" class="close" @click="showGodds = false">关闭</van-button>
        <van-button type="primary" class="sure" @click="doSureReplace">确认更换为选中商品</van-button>
      </div>
    </div>
  </van-popup>
</template>

<script>
import {channelInfoList, openLock, goodsList, vmcinfo, exchangeRecord} from '../../assets/api/site.js'
import { Toast } from "vant";
export default {
  data() {
    return {
      address: '',
      checked: [],
      checkedAll: false,
      checkboxRefs: [],
      channelList: [],
      showGodds: false,
      changedProdId: 0,
      changedProdName: '',
      goodsData: [],
      vmcId: this.$route.query.vmcId || '',
      replaceIdx: '',
      idsStr: '',
      loading: false
    }
  },
  mounted() {
    if (this.vmcId == '') {
      Toast.fail('请点击右上角扫描机器二维码')
      return
    }
    this.getVmcinfo()
    this.initDataList()
    this.getCountData()
    this.getGoodsList()
  },
  methods: {
    getVmcinfo() {
      vmcinfo({vmcId: this.vmcId}).then(res => {
        if (res.data.code == 200) {
          this.address = res.data.data.address
        }
      })
    },
    initDataList() {
      const params = {
        vmcId: this.vmcId,
        enable: 1
      }
      channelInfoList(params).then(res => {
        if (res.data.code == 200) {
          this.channelList = res.data.data
        }
      })
    },
    getCountData() {
      const params = {
        vmcId: this.vmcId,
        curCt: 1
      }
      channelInfoList(params).then(res => {
        if (res.data.code == 200) {
          this.num2 = res.data.data ? res.data.data.length : 0
        }
      })
    },
    getGoodsList() {
      goodsList({current: 1, size: 1000}).then(res => {
        if (res.data.code == 200) {
          this.goodsData = res.data.data.records
          if (this.goodsData.length == 0) {
            Toast('暂无商品')
            return
          }
          this.goodsData.unshift(
              { prodId: '', prodName: '', pic: require('../../assets/imgs/empty.png')}
          )
        }
      })
    },
    checkAll() {
      if (this.checked.length == this.channelList.length) {
        this.checked = []
      } else {
        this.channelList.forEach(item => {
          if (this.checked.indexOf(item.idx) < 0) {
            this.checked.push(item.idx)
          }
        })
      }
    },
    openLocks() {
      if (this.checked.length == 0) {
        Toast('请选择格口！')
        return
      }
      const idxStr = this.checked.join(',')
      const params = {
        vmcId: this.vmcId,
        idx: idxStr
      }
      openLock(params).then(res => {
        if (res.data.code == 200) {
          Toast.success('开锁成功')
        } else {
          Toast.fail('开锁失败')
        }
      })
    },
    doReplace() {
      if (this.checked.length == 0) {
        Toast('请选择格口！')
        return
      }
      this.showGodds = true
    },
    doSureReplace() {
      if (this.changedProdId == 0) {
        Toast('请选择更换商品!')
        return
      }
      let params = []
      let checkData = []
      this.channelList.forEach(item => {
        if (this.checked.indexOf(item.idx) > -1) {
          checkData.push({
            idx: item.idx,
            oldProductId: item.prodId,
            oldProductName: item.prodName
          })
        }
      })
      checkData.forEach(it => {
        const item = {
          vmcId: this.vmcId,
          channelId: it.idx,
          newProductId: this.changedProdId,
          newProductName: this.changedProdName,
          oldProductId: it.oldProductId,
          oldProductName: it.oldProductName,
          quantity: this.active, // active = 0 既是无货，无货换货时，quantity=0
          type: this.active == 1 ? 4 : 5  // 0：无货补货 ，1：有货补货，2：有货清空，3：无货清空  4:有货换货 5:无货换货
        }
        params.push(item)
      })
      exchangeRecord(params).then(res => {
        if (res.data.code == 200) {
          Toast('换货成功')
          this.showGodds = false
          window.location.reload()
        }
      })
    },
    doClear() {
      if (this.checked.length == 0) {
        Toast('请选择格口！')
        return
      }
      let params = []
      let checkData = []
      this.channelList.forEach(item => {
        if (this.checked.indexOf(item.idx) > -1) {
          checkData.push({
            idx: item.idx,
            newProductId: item.prodId,
            newProductName: item.prodName
          })
        }
      })
      checkData.forEach(it => {
        const item = {
          vmcId: this.vmcId,
          channelId: it.idx,
          newProductId: it.newProductId,
          newProductName: it.newProductName,
          quantity: 0, // 清空
          type: this.active == 1 ? 2 : 3  // 0：无货补货 ，1：有货补货，2：有货清空，3：无货清空  4:有货换货 5:无货换货
        }
        params.push(item)
      })
      exchangeRecord(params).then(res => {
        if (res.data.code == 200) {
          Toast('清空成功')
          window.location.reload()
        }
      })
    },
    async finishAddGoods() {
      if (this.checked.length == 0) {
        Toast('请选择需要补货的格口！')
        return
      }
      let params = []
      let checkData = []
      this.channelList.forEach(item => {
        if (this.checked.indexOf(item.idx) > -1) {
          checkData.push({
            idx: item.idx,
            newProductId: item.prodId,
            newProductName: item.prodName,
            curCt: item.curCt
          })
        }
      })
      checkData.forEach(it => {
        const item = {
          vmcId: this.vmcId,
          channelId: it.idx,
          newProductId: it.newProductId,
          newProductName: it.newProductName,
          quantity: 1, // 补货
          type: it.curCt ? 1 : 0 // this.active // 0：无货补货 ，1：有货补货，2：有货清空，3：无货清空  4:有货换货 5:无货换货
        }
        params.push(item)
      })
      this.loading = true
      await exchangeRecord(params).then(res => {
        if (res.data.code == 200) {
          this.loading = false
          Toast('补货成功')
          window.location.reload()
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-cell-group--inset) {
  margin: 0;
}

.warp {
  padding: 10px;
  background: #f3f3f3;

  .addr {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }

  .box {
    width: 100%;
    color: #666;
    font-size: 12px;
    border-radius: 8px;
    margin-top: 15px;
    position: relative;

    .idx-warp {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      background: #f3f3f3;
      .idx-box {
        width: calc(50% - 16px);
        border: 1px solid #d7d2d2;
        background: #fff;
        margin-bottom: 10px;
        height: 100px;
        position: relative;
        border-radius: 8px;
        padding: 5px;
        .up-info {
          height: 24px;
          display: flex;
          justify-content: space-between;
          .idx-nums {
            font-size: 20px;
            font-weight: bold;
          }
          .checkbox-item {
            position: absolute;
            right: 4px;
            top: 4px;
          }
        }
        .middle-info {
          display: flex;
          justify-content: space-between;
          margin-top: 10px;
          .prod-name {
            width: calc(100% - 65px);
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:2;//控制行数
            overflow:hidden;
            height: 32px;
          }
          .empty {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 0px;
            height: 0px;
            border-bottom: 35px solid #333;
            border-left: 40px solid transparent;
            opacity: 0.7;
          }
          .kong {
            position: absolute;
            bottom: 2px;
            right: 6px;
            z-index: 1;
            color: #fff;
          }
          .img-url {
            width: 60px;
            height: 60px;
            margin-top: 8px;
          }
        }
      }
      .div-disabled {
        background: #ffffffe6;
        filter: alpha(Opacity=50);
        -moz-opacity:0.5;
        opacity: 0.5;
      }
    }
  }
}
.btn-box {
  position: fixed;
  bottom: 0px;
  width: calc(100% - 20px);
  padding: 5px 10px;
  background: #fff;
  height: 60px;
  .btn {
    height: 28px;
    text-align: right;
    display: flex;
    line-height: 28px;
    justify-content: right;
  }
  .btn-2 {
    display: flex;
    justify-content: space-between;
    .btn-two {
      width: 18%;
    }
    .btn-four {
      width: 40%;
    }
    ::v-deep(.van-button) {
      font-size: 16px;
      padding: 0 5px;
    }
  }
}
.popup-box {
  padding: 10px;
  position: relative;

  .label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  .changed {
    border: 1px solid #2FB3E9;
  }
  .card {
    margin: 5px 0;
  }

  .btns {
    position: fixed;
    bottom: 0px;
    width: calc(100% - 20px);
    background: #fff;
    z-index: 999;
    height: 50px;

    .close {
      width: 80px;
      background: #999;
      color: #fff;
    }
    .sure {
      margin-left: 10px;
      width: calc(100% - 90px);
    }
  }
}
</style>
